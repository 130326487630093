<template>
  <div id="testInfo">
    <el-dialog
      title="输入密码"
      :visible.sync="passwordDialogVisible"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="banner">
      <div>{{ listData.name }}</div>
    </div>

    <div class="test">
      <div class="test-content">
        <div class="count-left">
          <p @click="clickRetrun">返回列表</p>
          <el-button
            v-show="shows && listData.id != 275"
            v-if="listData.pattern != '考试模式'"
            @click="praticeType(1)"
            >开始练习</el-button
          >
          <el-button
            v-show="shows"
            v-if="listData.pattern != '练习模式'"
            @click="testType(2)"
            >开始考试</el-button
          >
        </div>
        <div class="conunt-right">
          <div v-if="listData.pattern != '练习模式'">
            <div>考试时长</div>
            <p>{{ listData.durations }}<span>分钟</span></p>
          </div>
          <div v-if="listData.pattern != '练习模式'">
            <div>总分</div>
            <p>{{ listData.grade_sum }}</p>
          </div>
          <div>
            <div>难易程度</div>
            <div class="block">
              <el-rate
                disabled
                allow-half
                v-model="listData.difficultys"
                :colors="colors"
              >
              </el-rate>
            </div>
          </div>

          <div>
            <div>答题人数</div>
            <p>{{ listData.records_count + listData.falsedata }}</p>
          </div>
        </div>
      </div>
      <div class="introduction">
        <div class="introduction-left">
          <div class="title">
            <span>福利</span>
          </div>
          <div class="imgcontent">
            <img
              src="@/assets/image/num8jpg.jpg"
              alt=""
              v-if="$route.query.id != 275"
            />
            <img
              src="@/assets/image/9159ea2c11b42c76dbe814e4ec017ba.jpg"
              alt=""
              v-else
            />
          </div>
        </div>
        <div class="introduction-right">
          <div class="title">
            <span>温馨提示</span>
          </div>
          <ul>
            <li>
              试题内容如有错误，欢迎指正，感谢您的支持。我们定努力做到最好！
            </li>
            <li>
              如果您在使用西知题库过程中有任何问题和建议请与我们联系，请添加微信号反馈：a13811013052
            </li>
            <li>
              希望能为西语人提供最适合的答题库，题库功能将持续优化更新，敬请期待！
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 登录框 -->
    <LoginBox ref="loginBox"></LoginBox>
  </div>
</template>

<script>
import LoginBox from "@/components/LoginBox";
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  components: {
    LoginBox,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      listData: {
        records_count: 0,
        falsedata: 0,
      }, //详情数据
      titlename: "",
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      question: "",
      shows: false,
      ruleForm: {
        pass: "",
        checkPass: "",
        age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
      },
      passwordDialogVisible: false,
    };
  },
  methods: {
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            id: _this.listData.id,
            password: _this.ruleForm.pass,
          };

          _this.$api.passwordusercheck(obj).then((res) => {
            _this.passwordDialogVisible = false;
            let obj = {
              id: _this.listData.id,
              pattern: 2,
            };
            _this.$api.records(obj).then((res) => {
              _this.question = res.data.question_class_record;
              _this.$store.commit("changeCreated", _this.question);
              _this.$store.commit("changeRowObj", _this.listData);
              localStorage.name = _this.listData.name;
              _this.$router.push("/Index/Notice");
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clickRetrun() {
      let _this = this;
      _this.$router.push("/Index/Home");
    },
    //练习模式
    praticeType(num) {
      let _this = this;

      let userInfo = localStorage.userInfo; //用户是否登录
      if (userInfo) {
        let obj = {
          id: _this.listData.id,
          pattern: num,
        };
        _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.listData);
          localStorage.name = _this.listData.name;
          this.$router.push("/Index/Pratice");
        });
      } else {
        // _this.$message.error("您还未登录,请登录");

        _this.$refs.loginBox.clickloginDialogBool();
      }
    },
    //考试模式
    testType(num) {
      let _this = this;

      let userInfo = localStorage.userInfo; //用户是否登录
      if (userInfo) {
        if (_this.listData.password) {
          _this.$api
            .APIpasswordusercheck({
              question_class_id: _this.listData.id,
            })
            .then((res) => {
              if (res.data.question_class_password_user) {
                _this.$message("恭喜您已完成本次专四模拟考试，不可再次答题哦。");
              } else {
                _this.passwordDialogVisible = true;
              }
            });
        } else {
          let obj = {
            id: _this.listData.id,
            pattern: num,
          };
          _this.$api.records(obj).then((res) => {
            _this.question = res.data.question_class_record;
            _this.$store.commit("changeCreated", _this.question);
            _this.$store.commit("changeRowObj", _this.listData);
            localStorage.name = _this.listData.name;
            _this.$router.push("/Index/Notice");
          });
        }
      } else {
        // _this.$message.error("您还未登录,请登录");

        _this.$refs.loginBox.clickloginDialogBool();
      }
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
        window.location.href =
          "http://m.tk.spanishknow.com/#/TestInfo?id=" + this.$route.query.id;
        // window.location.href = "http://192.168.1.103:3333/#/TestInfo?id="+this.$route.query.id;
      } else {
        //跳转pc端页面
        console.log("pc端页面");
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
    _this.loading = _this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    let obj = {
      id: _this.$route.query.id,
    };
    // _this.id = _this.$route.query.id;
    _this.$api.questionclassshow(obj).then((res) => {
      _this.listData = res.data.list;
      console.log(_this.listData);
      _this.shows = true;
      // _this.listData.pattern = "考试模式";
      // _this.listData.pattern = "练习模式";

      // console.log(_this.listData.pattern);
      _this.titlename = _this.listData.name + " - 西知题库";
      _this.loading.close();
    });
    _this.$store.commit("changeurl", window.location.href);
    _this.browserRedirect();
  },
};
</script>

<style lang="less" scoped>
@redColor: #df3833;
#testInfo {
  background: #f3f3f4;
  .banner {
    width: 100%;
    height: 250px;
    background: url("../../assets/image/list-banner.png");
    padding-top: 46px;
    div {
      width: 1180px;
      height: 46px;
      line-height: 46px;
      margin: auto;
      font-size: 36px;
      font-weight: 600;
    }
  }
  .test {
    // height: 1000px;
    width: 1180px;
    margin: 0 auto;
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    .test-content {
      width: 1180px;
      height: 140px;
      padding: 28px;
      background: #fff;
      box-shadow: 0px 0px 10px #cfcfcf;
      position: absolute;
      align-items: center;
      top: -70px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .count-left {
        display: flex;
        align-content: center;
        width: 460px;
        padding: 12px 0;
        justify-content: space-between;
        padding-right: 50px;
        border-right: 1px solid #dfe1e6;
        p {
          cursor: pointer;
          margin-top: 10px;
          padding-left: 30px;
          font-weight: 400px;
          color: #575d6c;
          font-size: 16px !important;
          vertical-align: sub;
          background: url(../../assets/image/return.png) 0 0px no-repeat;
          background-size: 24px 24px;
        }
        .el-button {
          width: 116px;
          height: 40px;
          background: @redColor;
          border-color: @redColor;
          color: #fff;
        }
      }

      .conunt-right {
        width: 680px;
        display: flex;
        padding-left: 20px;

        > div {
          width: 150px;
          text-align: center;
          div {
            font-size: 14px;
            font-weight: 400px;
            color: #575d6c;
            margin-bottom: 20px;
          }
          p {
            font-size: 30px;
            font-weight: 400;
            color: rgba(37, 43, 58, 1);
            span {
              font-size: 14px;
              font-weight: 400;
              color: #575d6c;
              line-height: 22px;
              padding-left: 5px;
            }
          }
          &:last-child {
            margin-left: 30px;
          }
        }
        /deep/.el-rate__icon {
          font-size: 28px !important;
          margin-right: 0px;
        }
      }
    }
    .introduction {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .introduction-left {
        background-color: #fff;
        margin-top: 15px;
        padding-top: 0px;
        padding-bottom: 0;
        width: 810px;
        margin: 0;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        .title {
          padding: 0 30px;
          font-size: 18px;
          border-bottom: 1px solid #dfe1e6;
          overflow: hidden;
          line-height: 68px;
          span {
            border-bottom: 3px solid @redColor;
            padding-bottom: 21px;
            color: #252b3a;
          }
        }
        .imgcontent {
          padding: 30px;
          img {
            width: 500px;
          }
        }
      }
      .introduction-right {
        background-color: #fff;
        width: 350px;
        padding: 0 30px;
        height: 350px;
        .title {
          //   padding: 0 30px;
          font-size: 18px;
          overflow: hidden;
          line-height: 68px;

          span {
            color: #252b3a;

            font-weight: 600;
          }
        }
        ul {
          li {
            color: #2f3742;
            word-break: break-all;
            word-wrap: break-word;
            padding-left: 20px;
            line-height: 30px;
            margin-bottom: 20px;
            font-size: 14px;
            &:before {
              position: absolute;
              left: 0;
              top: 13px;
              content: "";
              display: inline-block;
              background: #2f3742;
              width: 5px;
              height: 5px;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}
</style>